import { UseFrontendUser } from "../auth/server/frontend";
import { getOrganizationalChart } from "./data/organization";
import { subordinateEmployees, subordinatePositions } from "../organization";
import { getFullName } from "../string";

const hiddenOrganisation: string[] = [
    // Live
    "b0e19eef-a015-4588-9c38-f8b6bfbc24b3",
    "8dec1889-b69f-4af4-bd1c-acd65dc3ff2c",

    // Integration
    "9982faf9-ef1a-4156-af92-847e3399e476",
    "550a3892-93ee-4f83-ba9e-8c3429392abc",
    "cbd8d569-216b-40d3-b142-b22e08c1fab3",
];

const hiddenPositions: string[] = [];

const hiddenEmployees: string[] = [
    // Live
    "c251e3ae-9bf6-449a-a551-0b0b6a9c2d4a",
    "29314f4d-e3f4-40d7-89e0-983e36f5a44c",

    // Integration
    "a8b1074b-a5dc-4c38-ac28-c5b4b6fc2679",
    "65e32d98-8265-4f3c-8169-dd7188c62c1b",
    "bba05a4f-205d-4617-b644-179143e4839b",
    "5fbe0f85-b039-4892-acd4-1e9372533923",
];

export const getStaticPageOrganization = async () => {
    const token = await UseFrontendUser();
    const organization = await getOrganizationalChart(token);

    const orgEmployees = organization.orgChartEmployees.filter(employee =>
        hiddenEmployees.every(id => id !== employee.id)
    );

    const orgPositions = organization.orgChartPositions
        .filter(position => hiddenPositions.every(id => id !== position.id))
        .map(position => ({
            ...position,
            employees:
                position?.employees?.filter(employee =>
                    hiddenEmployees.every(id => id !== employee.id)
                ) ?? [],
        }));

    const orgOganisations = organization.orgChartOrganisations
        .filter(organisation => hiddenOrganisation.every(id => id !== organisation.id))
        .map(organisation => ({
            ...organisation,
            positions:
                organisation?.positions
                    ?.filter(position => hiddenPositions.every(id => id !== position.id))
                    ?.map(position => ({
                        ...position,
                        employees:
                            position?.employees?.filter(employee =>
                                hiddenEmployees.every(id => id !== employee.id)
                            ) ?? [],
                    })) ?? [],
        }));

    const employees = orgEmployees;
    const positions = subordinateEmployees(orgPositions, orgEmployees);
    const departments = subordinatePositions(orgOganisations, positions);

    return {
        employees,
        departments,
        positions,
    };
};

export const filterOrganizationByStr = (
    str: string,
    organization: Organization.Index
): Organization.Index => {
    const employees = organization.employees.filter(employee => {
        return getFullName(employee).toLowerCase().includes(str.toLowerCase());
    });

    const departments = organization.departments.filter(department => {
        return department.designation.toLowerCase().includes(str.toLowerCase());
    });

    return {
        employees: employees.slice(0, 10),
        departments: departments.slice(0, 10),
        positions: [],
    };
};
