import { weatherConfig } from "./config/weather";

const locations: OpenWeather.Location[] = [
    { city: "Binzen", country: "DE" },
    { city: "Debrecen", country: "HU" },
    { city: "Hvidovre", country: "DK" },
    { city: "Lodz", country: "PL" },
    { city: "Schopfheim", country: "DE" },
    { city: "Waldkirch", country: "DE" },
    { city: "Gebesee", country: "DE" },
];

export const getCurrentWeatherByLocation = async (
    city: string,
    country: string,
    locale: string
): Promise<OpenWeather.Response | null> => {
    const res = await fetch(
        `${weatherConfig.host}/weather?q=${city},${country}&units=metric&lang=${locale}&appid=${weatherConfig.apiKey}`
    );

    if (!res.ok) return null;

    return await res.json();
};

export const getCurrentWeather = async (locale: string): Promise<OpenWeather.Response[] | null> => {
    const res = await Promise.all(
        locations.map(location =>
            getCurrentWeatherByLocation(location.city, location.country, locale)
        )
    );

    const filteredRes = res.filter(res => res !== null) as OpenWeather.Response[];

    return filteredRes.length > 0 ? filteredRes : null;
};
